<template>
  <v-container class="d-flex">
    <div max-width="400px" class="mx-auto my-auto">
      <v-img src="/static/img/referrer/fortsafety/logo.png" max-width="122px" class="mx-auto" />
      <div class="ma-4 text-center">
        <span>Congrats!  You get a 14 day free trial for being referred by Fort Safety, Inc.</span><br/>
        <span class="caption font-italic">(new customers only)</span>
      </div>
      <v-btn to="/login" color="primary" block>Get Started</v-btn>
    </div>
  </v-container>
</template>

<script>
import StoreConstants from "@/StoreConstants";

export default {
  name: "FortSafety",
  mounted() {
    this.$store.commit(StoreConstants.commits.setReferrer, 'https://www.fortsafety.com')
  }
}
</script>

<style scoped>

</style>