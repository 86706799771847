var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "d-flex" }, [
    _c(
      "div",
      { staticClass: "mx-auto my-auto", attrs: { "max-width": "400px" } },
      [
        _c("v-img", {
          staticClass: "mx-auto",
          attrs: {
            src: "/static/img/referrer/fortsafety/logo.png",
            "max-width": "122px"
          }
        }),
        _c("div", { staticClass: "ma-4 text-center" }, [
          _c("span", [
            _vm._v(
              "Congrats!  You get a 14 day free trial for being referred by Fort Safety, Inc."
            )
          ]),
          _c("br"),
          _c("span", { staticClass: "caption font-italic" }, [
            _vm._v("(new customers only)")
          ])
        ]),
        _c("v-btn", { attrs: { to: "/login", color: "primary", block: "" } }, [
          _vm._v("Get Started")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }